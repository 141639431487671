'use client';

import { useAnalytics } from '@/app/components/Analytics';
import { usePartner } from '@/lib/partners/usePartner/client';
import classNames from 'classnames';
import NextLink from 'next/link';
import { ReactNode } from 'react';

type ButtonProps = {
  id: string;
  children: ReactNode;
  onClick?: () => void;
  mixpanelButtonText: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'icon-square';
  disabled?: boolean;
  ariaLabel?: string;
  implementAs?: 'button' | 'a' | 'NextLink';
  href?: string;
};

const Button = ({
  id,
  children,
  onClick,
  mixpanelButtonText,
  type = 'button',
  disabled = false,
  variant = 'primary',
  ariaLabel,
  implementAs = 'button',
  href = '/',
}: ButtonProps) => {
  const analytics = useAnalytics();
  const partner = usePartner();
  const Tag = implementAs;

  let variantClassNames: string = '';

  const commonStyles = [
    'transition-colors',
    'duration-300',
    'w-full',
    'tablet:min-h-10',
    'min-h-9',
    'text-body-4',
    'tablet:text-body-3',
  ];

  switch (variant) {
    case 'primary': {
      variantClassNames = classNames(
        ...commonStyles,
        'rounded-3xl',
        'border-2',
        'focus:text-core-9',
        'focus:bg-buttonBackgroundColorHover',
        'focus:underline',
        'focus:underline-offset-2',
        {
          'bg-buttonBackgroundColor': !disabled,
          'hover:bg-buttonBackgroundColorHover': !disabled,
          'text-buttonTextColor': !disabled,
          // do properly once other buttons are converted
          // 'hover:text-buttonTextColorHover',
          'hover:text-core-8': !disabled,
          'border-core-8': !disabled,
          'pointer-events-none': disabled,
          'text-core-7': disabled,
          'bg-core-3': disabled,
          'border-core-3': disabled,
        },
      );
      break;
    }
    case 'secondary': {
      variantClassNames = classNames(
        ...commonStyles,
        'rounded-3xl',
        'border-2',
        'focus:bg-buttonBackgroundColorHover',
        'focus:underline',
        'focus:underline-offset-2',
        'focus:border-4',
        'focus:text-core-8',
        {
          'bg-core-8': !disabled,
          'text-buttonTextColor': !disabled,
          'border-core-8': !disabled,
          'pointer-events-none': disabled,
          'text-core-7': disabled,
          'bg-core-3': disabled,
          'border-core-3': disabled,
        },
      );
      break;
    }
    case 'tertiary': {
      variantClassNames = classNames(
        ...commonStyles,
        'focus:text-core-8',
        'focus:underline',
        'focus:underline-offset-2',
        'focus:outline-none',
        {
          'text-core-7': !disabled,
          'hover:text-core-8': !disabled,
          'hover:underline': !disabled,
          'hover:underline-offset-2': !disabled,
          'pointer-events-none': disabled,
          // bad axe 2.11 contrast
          'text-core-6': disabled,
        },
      );
    }
  }

  return Tag === 'NextLink' ? (
    <NextLink
      href={href}
      className={`${variantClassNames} flex items-center justify-center`}
      aria-label={ariaLabel}
    >
      {children}
    </NextLink>
  ) : (
    <Tag
      id={id}
      className={variantClassNames}
      onClick={() => {
        onClick?.();
        analytics?.trackButtonClick(partner, mixpanelButtonText, id);
      }}
      type={type}
      disabled={disabled}
      aria-label={ariaLabel}
      href={href}
    >
      {children}
    </Tag>
  );
};

export { Button, type ButtonProps };
