'use client';
import classNames from 'classnames';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';

type LinkProps = {
  href: string;
  children: React.ReactNode;
  variant?: 'primary-nav' | 'body-text' | 'chart-body-text';
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

const Link = ({ href, children, variant, onClick }: LinkProps) => {
  const pathname = usePathname();

  let variantClassNames: (string | Record<string, boolean>)[] = [];

  switch (variant) {
    case 'primary-nav': {
      variantClassNames = [
        { 'hover:bg-core-4': pathname !== href },
        'active:bg-core-8',
        'active:text-core-1',
        'rounded-lg',
        'py-k-4',
        'pl-k-4',
        'transition-colors',
        'duration-300',
        'w-full',
        'flex',
        'items-center',
        {
          'pointer-events-none': pathname === href,
          'text-core-1': pathname === href,
          'bg-core-8': pathname === href,
        },
      ];
      break;
    }
    case 'body-text': {
      variantClassNames = [
        'text-link',
        'hover:text-link-hover',
        'focus:text-link-focus',
        'active:link-selected',
        'mb-0',
        'underline',
      ];
      break;
    }
    case 'chart-body-text': {
      variantClassNames = [
        'text-core-7',
        'text-body-4-light',
        'hover:text-core-9',
      ];
      break;
    }
  }

  return (
    <NextLink
      href={href}
      className={classNames([...variantClassNames])}
      onClick={(e) => {
        onClick?.(e);
      }}
    >
      {children}
    </NextLink>
  );
};

export { Link };
