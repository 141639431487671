const MenuSharp = () => (
  <svg
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    tabIndex={-1}
    line-height="1"
    height="1.5em"
    width="1.5em"
  >
    <path
      fill="currentColor"
      d="M3 18h18v-2H3zm0-5h18v-2H3zm0-7v2h18V6z"
    ></path>
  </svg>
);

export { MenuSharp };
