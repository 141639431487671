const CloseSharp = () => (
  <svg
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    tabIndex={-1}
    line-height="1"
    height="1.5em"
    width="1.5em"
  >
    <path
      d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      fill="currentColor"
    ></path>
  </svg>
);

export { CloseSharp };
