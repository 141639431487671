'use client';
import { CloseSharp } from '@/app/components/icons/CloseSharp';
import { InfoSharp } from '@/app/components/icons/InfoSharp';
import { Home } from '@/app/components/icons/Home';
import { IconWrapper } from '@/app/components/icons/IconWrapper';
import { LogoutSharp } from '@/app/components/icons/LogoutSharp';
import { MenuSharp } from '@/app/components/icons/MenuSharp';
import { Person } from '@/app/components/icons/Person';
import { SwapHorizontal } from '@/app/components/icons/SwapHorizontal';
import { Link } from '@/app/components/Link/Link';
import { useTranslation } from '@/app/i18n/client';
import { Route } from '@/lib/pageroutes';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { useUser } from '@auth0/nextjs-auth0/client';
import classNames from 'classnames';
import Image from 'next/image';
import { useRef, useState } from 'react';

const Sidebar = () => {
  const { user } = useUser();
  const [menuOpen, setMenuOpen] = useState(false);
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const { t } = useTranslation();
  const menuButton = useRef<HTMLButtonElement>(null);
  const closeMenu = () => setMenuOpen(false);

  return (
    <div
      aria-live="polite"
      className={classNames(
        'flex',
        'flex-col',
        { 'h-screen': menuOpen, fixed: menuOpen, 'z-50': menuOpen },
        'py-k-6',
        'w-full',
        'bg-white',
        'laptop:h-screen',
        'laptop:z-auto',
        'laptop:min-w-[280px]',
        'laptop:max-w-[300px]',
        'laptop:sticky',
        'laptop:top-0',
        'laptop:border-r',
        'laptop:border-core-5',
        'laptop:px-k-7',
        'laptop:py-k-9',
        'laptop:bg-core-2',
        'overflow-auto',
      )}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          setMenuOpen(false);
          menuButton.current?.focus();
        }
      }}
    >
      <div
        className={classNames([
          'flex',
          'items-center',
          'justify-between',
          'laptop:items-start',
          'px-k-6',
          'laptop:px-0',
        ])}
      >
        <button
          ref={menuButton}
          className="laptop:hidden"
          onClick={() => setMenuOpen(!menuOpen)}
          aria-label={
            menuOpen ? t('dashboard-menu-close') : t('dashboard-menu-open')
          }
        >
          {menuOpen ? (
            <IconWrapper>
              <CloseSharp />
            </IconWrapper>
          ) : (
            <IconWrapper>
              <MenuSharp />
            </IconWrapper>
          )}
        </button>
        <div>
          <Image
            src={`${getEnvVar('NEXT_PUBLIC_CDN')}${partnerSettings.imagesPath}${
              partnerSettings.images.logo
            }`}
            width={220}
            alt={
              partnerSettings.logoALTText ||
              partnerSettings.displayName ||
              partner
            }
            height={40}
            priority
            className={classNames([
              'max-h-[28px]',
              'my-auto',
              'w-full',
              'laptop:max-w-[220px]',
              'mr-[20px]',
              'laptop:mr-0',
              'max-w-[160px]',
              'mobile:max-w-[190px]',
            ])}
          />
        </div>
      </div>
      <div
        className={classNames(
          'flex-col',
          'flex-1',
          'flex',
          {
            hidden: !menuOpen,
          },
          'laptop:flex',
          'justify-end',
        )}
      >
        <nav
          className={classNames([
            'mt-24',
            'laptop:mt-k-10',
            'laptop:flex-1',
            'px-k-6',
            'laptop:px-0',
          ])}
        >
          <ul className="list-none p-0">
            <li className="mb-k-5">
              <p className="laptop:text-body-4-light text-body-1-light">
                <Link
                  variant="primary-nav"
                  href={Route.DASHBOARD}
                  onClick={closeMenu}
                >
                  <span className="inline-block h-k-7 w-k-7 mr-k-4">
                    <Home />
                  </span>
                  {t('dashboard-menu-link-dashboard')}
                </Link>
              </p>
            </li>
            <li className="mb-k-5">
              <p className="laptop:text-body-4-light text-body-1-light">
                <Link
                  variant="primary-nav"
                  href={Route.PAYMENT_HISTORY}
                  onClick={closeMenu}
                >
                  <span className="inline-block h-k-7 w-k-7 mr-k-2">
                    <SwapHorizontal />
                  </span>
                  {t('dashboard-menu-link-payment-history')}
                </Link>
              </p>
            </li>
            <li className="mb-k-5">
              <p className="laptop:text-body-4-light text-body-1-light">
                <Link
                  variant="primary-nav"
                  href={Route.DETAILS}
                  onClick={closeMenu}
                >
                  <span className="inline-block h-k-7 w-k-7 mr-k-2">
                    <Person />
                  </span>
                  {t('dashboard-menu-link-your-details')}
                </Link>
              </p>
            </li>
          </ul>
        </nav>
        <div className="mt-auto px-k-6 laptop:px-0 laptop:mx-0">
          <div className="mb-k-5">
            <p className="laptop:text-body-4-light text-body-2-light">
              <Link href="/help-center" variant="primary-nav">
                <span className="inline-block h-k-7 w-k-7 mr-k-2">
                  <InfoSharp />
                </span>
                {t('dashboard-menu-link-help-center')}
              </Link>
            </p>
          </div>
          {user && (
            <div>
              <p className="laptop:text-body-4-light text-body-2-light">
                <a
                  className={classNames(
                    'hover:bg-core-4',
                    'active:bg-core-8',
                    'active:text-core-1',
                    'rounded-lg',
                    'py-k-4',
                    'pl-k-4',
                    'transition-colors',
                    'duration-300',
                    'w-full',
                    'flex',
                    'items-center',
                  )}
                  href={`/api/${partner}/auth/logout`}
                >
                  <span className="inline-block h-k-7 w-k-7 mr-k-2">
                    <LogoutSharp />
                  </span>
                  {t('dashboard-menu-link-sign-out')}
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { Sidebar };
