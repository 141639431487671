const Home = () => (
  <svg
    focusable="false"
    aria-hidden="true"
    tabIndex={-1}
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 21V9L12 3L20 9V21H14V14H10V21H4Z" fill="currentColor" />
  </svg>
);
export { Home };
