const LogoutSharp = () => (
  <svg
    focusable="false"
    aria-hidden="true"
    tabIndex={-1}
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 21V15H5V19H19V5H5V9H3V3H21V21H3ZM10.5 17L9.1 15.55L11.65 13H3V11H11.65L9.1 8.45L10.5 7L15.5 12L10.5 17Z"
      fill="currentColor"
    />
  </svg>
);
export { LogoutSharp };
