import classNames from 'classnames';

const IconWrapper: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => (
  <span
    className={classNames('inline-block', 'mr-5', 'align-bottom', className)}
  >
    {children}
  </span>
);

export { IconWrapper };
