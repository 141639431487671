import { getPartnerSettings } from '@/lib/partners/settings/getSettings';

const getLinks = (partner: string): { label: string; url: string }[] => {
  const partnerSettings: any = getPartnerSettings(partner);

  const options = [
    { label: 'common-footer-terms', url: 'terms-and-conditions-url' },
    { label: 'common-footer-privacy', url: 'privacy-policy-url' },
    { label: 'common-footer-cookie', url: 'cookie-policy-url' },
  ];
  const footerLinks: { label: string; url: string }[] = [];

  options.forEach((option) => {
    if (partnerSettings[option.url]) {
      footerLinks.push({ label: option.label, url: partnerSettings[option.url] });
    }
  });

  return footerLinks;
};

export { getLinks };
