'use client';
import { useTranslation } from '@/app/i18n/client';
import { getLinks } from '@/app/components/Footer/getLinks';

const Footer = ({
  partner,
  disclaimer,
}: {
  partner: string;
  disclaimer?: string;
}) => {
  const { t } = useTranslation();
  const links = getLinks(partner);

  return (
    <footer className="footer bg-core-2 py-6 flex flex-col space-y-6 px-8 text-body-4-light mt-10">
      {disclaimer && <p>{disclaimer}</p>}
      <p>{t('common-footer-main')}</p>
      <div className="flex space-x-14">
        {links.map((link) => (
          <a
            target="_blank"
            href={link.url}
            key={link.label}
            className="underline text-body-5 tablet:text-body-4"
          >
            {t(link.label)}
          </a>
        ))}
      </div>
    </footer>
  );
};
export default Footer;
