import { CloseButton } from '@/app/[lang]/[partner]/(ActiveCustomer)/components/CloseButton';
import { useTranslation } from '@/app/i18n/client';
import classNames from 'classnames';
import { usePathname, useRouter } from 'next/navigation';

const Header = ({
  pages,
  navigateUrlOnHeaderClose,
}: {
  pages: {
    label: string;
    path: string;
  }[];
  navigateUrlOnHeaderClose?: string;
}) => {
  const pathname = usePathname();
  const currentStepIndex = pages.findIndex((page) => page.path === pathname);

  const { t } = useTranslation();

  const router = useRouter();

  const handleExitRenewalsJourney = (navigateUrl: string) => {
    router.push(navigateUrl);
  };

  return (
    <header
      className={classNames(
        'flex',
        'flex-row',
        'flex-grow',
        'w-full',
        'justify-center',
        'p-4',
        'border-core-4',
        'border-b-[1px]',
      )}
    >
      <div
        className={classNames(
          'max-w-2xl',
          'w-full',
          'flex',
          'flex-row',
          'justify-center',
          'gap-[11px]',
        )}
      >
        {pages.map((page, i) => (
          <div key={JSON.stringify(page)} className="flex-auto basis-0">
            <div
              className={classNames(
                i <= currentStepIndex ? 'bg-primary' : 'bg-core-4',
                'rounded-full',
                'h-[6px]',
              )}
            />
            <p
              className={classNames(
                'text-center',
                'text-sm',
                'text-nowrap',
                'mt-1',
              )}
            >
              {page.label}
            </p>
          </div>
        ))}
      </div>
      {navigateUrlOnHeaderClose && (
        <div className="absolute right-4">
          <CloseButton
            id="ace-renewals-journey-close-button"
            onClick={() => handleExitRenewalsJourney(navigateUrlOnHeaderClose)}
            mixpanelButtonText="ACERenewalsJourneyCloseButton"
            ariaLabel={t('renewal-journey-exit-label-text')}
          />
        </div>
      )}
    </header>
  );
};

export { Header };

